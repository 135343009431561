import {
    Box,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import MenuBar from '../common/components/MenuBar';
import PageWidthContainer from '../common/components/PageWidthContainer';
import Footer from '../common/components/Footer';

const Imprint = () => (

    <>
        <MenuBar invertColor />

        <PageWidthContainer>
            <VStack
                align="left"
                paddingBlock="4rem"
                spacing="1rem"
            >
                <Heading size="lg">
                    Impressum / Angaben gemäß § 5 TMG
                </Heading>

                <Text>
                    Thorsten Lamberty
                    <br />
                    Hohe Bleichen 22
                    <br />
                    20345 Hamburg
                    <br />
                    Germany
                    <br />
                    Tel.: +49 89 5147 0703
                    <br />

                    {'E-Mail: '}

                    <Link
                        color="wvwYellow"
                        href="mailto:platform@wer-bewertet-was.de"
                        isExternal
                    >
                        platform@wer-bewertet-was.de
                    </Link>

                    {' | Web: '}

                    <Link
                        as={ReactLink}
                        color="wvwYellow"
                        to="/"
                    >
                        wer-bewertet-was.de
                    </Link>
                </Text>

                <Heading size="sm">
                    Rechtsform:
                </Heading>

                <Text>
                    Gesellschaft mit beschränkter Haftung (GmbH) mit Sitz in Hamburg
                </Text>

                <Heading size="sm">
                    Vertreten durch:
                </Heading>

                <Text>
                    Thorsten Lamberty
                </Text>

                <Heading size="sm">
                    Registereintrag:
                </Heading>

                <Text>
                    Eintragung im Handelsregister
                    <br />
                    Registergericht: Amtsgericht Hamburg
                    <br />
                    Register-Nr. HRB 162977
                </Text>

                <Heading size="sm">
                    Register-Nr. HRB 162977
                </Heading>

                <Text>
                    DE330771292
                </Text>

                <Heading size="sm">
                    Hinweis zu Links:
                </Heading>

                <Text>
                    {/* eslint-disable-next-line max-len */}
                    Soweit auf andere Internet-Seiten verwiesen wird (Hyperlinks), stellen diese keine Meinung oder Inhalte der WHO VALUES WHAT dar.
                </Text>
            </VStack>
        </PageWidthContainer>

        <Box bg="grey05">
            <Footer />
        </Box>
    </>
);

export default Imprint;
