import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route
                index
                element={<Home />}
            />

            <Route
                path="contact"
                element={<ContactUs />}
            />

            <Route
                path="privacy"
                element={<Privacy />}
            />

            <Route
                path="terms"
                element={<Terms />}
            />

            <Route
                path="imprint"
                element={<Imprint />}
            />

            <Route
                path="*"
                element={(
                    <div>
                        <h1>Not Found</h1>
                    </div>
                )}
            />
        </Routes>
    </BrowserRouter>
);

export default Router;
