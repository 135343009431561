import {
    Center, Spinner, Text, VStack,
} from '@chakra-ui/react';

type PropTypes = {
    color?: string;
    noText?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    thickness?: string;
};

const LoadingSpinner = (props: PropTypes) => {
    const {
        color,
        noText,
        size,
        thickness,
    } = props;

    return (
        <Center w="100%">
            <VStack
                spacing="4"
                w="100%"
            >
                <Spinner
                    color={color}
                    size={size}
                    thickness={thickness}
                />

                { !noText && (
                    <Text>
                        Loading...
                    </Text>
                ) }
            </VStack>
        </Center>
    );
};

LoadingSpinner.defaultProps = {
    color: 'orange',
    noText: false,
    size: 'md',
    thickness: undefined,
};

export default LoadingSpinner;
