import {
    Textarea,
    Text,
    Box,
} from '@chakra-ui/react';
import { useField } from 'formik';

type PropTypes = {
    name: string;
    placeholder?: string;
};

const FormikTextarea = (props: PropTypes) => {
    const {
        name,
        placeholder,
    } = props;

    const [field, meta] = useField(props);

    return (
        <Box
            textAlign="left"
            w="100%"
        >
            <Textarea
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                name={name}
                placeholder={placeholder}
                bg="white"
                borderRadius="10"
            />

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {meta.error}
                    </Text>
                ) }
        </Box>
    );
};

FormikTextarea.defaultProps = {
    placeholder: '',
};

export default FormikTextarea;
