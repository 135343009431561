import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const { REACT_APP_API_URL } = process.env;

type MessageType = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    town: string;
    country: string;
    message: string;
    recaptchaToken: string;
};

type ParamsType = {
    onSuccess: (data?: unknown) => void;
    onError: (error?: unknown) => void;
};

const useSendContactEmail = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: send } = useMutation({
        mutationFn: (message: MessageType) => axios.post(
            `${REACT_APP_API_URL}/email/email-platform`,
            message,
        ),
        onSuccess: (data: unknown) => {
            onSuccess?.(data);
        },
        onError: (error: unknown) => {
            onError?.(error);
        },
    });

    return {
        send,
    };
};

export default useSendContactEmail;
