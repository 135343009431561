import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        darkBlue: '#014A4F',
        green: 'rgba(1, 96, 100)',
        green80: 'rgba(1, 96, 100, .80)',
        green60: 'rgba(1, 96, 100, .60)',
        green40: 'rgba(1, 96, 100, .40)',
        green20: 'rgba(1, 96, 100, .20)',
        green05: 'rgba(1, 96, 100, .05)',
        red: 'rgba(233, 74, 68)',
        red80: 'rgba(233, 74, 68, .80)',
        red60: 'rgba(233, 74, 68, .60)',
        red40: 'rgba(233, 74, 68, .40)',
        red20: 'rgba(233, 74, 68, .20)',
        red10: 'rgba(233, 74, 68, .10)',
        red05: 'rgba(233, 74, 68, .05)',
        grey: 'rgba(62, 62, 64)',
        grey80: 'rgba(62, 62, 64, 0.8)',
        grey60: 'rgba(62, 62, 64, 0.6)',
        grey40: 'rgba(62, 62, 64, 0.4)',
        grey20: 'rgba(62, 62, 64, 0.2)',
        grey10: 'rgba(62, 62, 64, 0.1)',
        grey05: 'rgba(62, 62, 64, 0.05)',
        grey01: 'rgba(62, 62, 64, 0.01)',
        white: '#ffffff',
        yellow: 'rgba(250, 202, 22)',
        yellow80: 'rgba(250, 202, 22, .80)',
        yellow60: 'rgba(250, 202, 22, .60)',
        yellow40: 'rgba(250, 202, 22, .40)',
        yellow20: 'rgba(250, 202, 22, .20)',
        yellow10: 'rgba(250, 202, 22, .10)',
    },
    components: {
        Button: {
            variants: {
                primary: {
                    border: '2px solid',
                    borderColor: 'green',
                    color: 'white',
                    bg: 'green',
                    _hover: {
                        bg: 'darkBlue',
                    },
                },
                primaryYellow: {
                    borderRadius: '2rem',
                    bg: 'yellow',
                    color: 'white',
                    _hover: {
                        borderColor: '#FB8A20',
                        color: 'white',
                        textDecoration: 'underline',
                    },
                },
                primaryGreen: {
                    border: '2px solid',
                    borderColor: 'green',
                    color: 'white',
                    bg: 'green',
                    _hover: {
                        borderColor: '#12BC61',
                        bg: '#12BC61',
                        color: 'white',
                    },
                },
                primaryWhite: {
                    border: '2px solid',
                    borderColor: 'white',
                    color: 'green',
                    bg: 'white',
                    _hover: {
                        bg: 'green',
                        color: 'white',
                    },
                },
                secondary: {
                    border: '2px solid',
                    borderColor: 'white',
                    bg: 'white',
                    color: 'green',
                    _hover: {
                        bg: '#fff5eb',
                        borderColor: '#ff9e43',
                    },
                },
                danger: {
                    border: '2px solid',
                    borderColor: 'red',
                    color: 'white',
                    bg: 'red',
                    _hover: {
                        bg: '#DD423C',
                        borderColor: '#DD423C',
                    },
                },
                outline: {
                    border: '2px solid',
                    borderColor: 'green',
                    color: 'green',
                    _hover: {
                        color: 'green',
                    },
                },
                outline1: {
                    border: '2px solid',
                    bgColor: 'none',
                    borderColor: 'yellow',
                    color: 'yellow',
                    _hover: {
                        color: 'white',
                        bg: 'yellow',
                    },
                },
                menu: {
                    color: 'grey80',
                    bg: 'none',
                    fontWeight: 'normal',
                    fontSize: '1rem',
                    width: '12rem',
                    height: '3rem',
                    justifyContent: 'left',
                    _hover: {
                        paddingLeft: '1.5rem',
                    },
                },
                menuActive: {
                    color: 'white',
                    bg: 'green',
                    fontWeight: 'normal',
                    fontSize: '1rem',
                    width: '12rem',
                    height: '3rem',
                    justifyContent: 'left',
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    border: '1px solid',
                    borderColor: 'green',
                    _checked: {
                        iconColor: 'white',
                        bg: 'green',
                        borderColor: 'green',
                    },
                },
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    border: '1px solid',
                    borderColor: 'green',
                    _checked: {
                        bg: 'white',
                        color: 'green',
                    },
                },
            },
        },
        Tabs: {
            variants: {
                active: {
                    Tab: {
                        color: 'grey40',
                        _selected: {
                            color: 'green',
                            fontWeight: 'bold',
                        },
                    },
                },
            },
        },
        UnorderedList: {
            colorScheme: {
                blue: {
                    text: {
                        color: 'green',
                    },
                },
            },
        },
        Select: {
            defaultProps: {
                background: 'white',
                height: '3rem',
                borderRadius: '8',
            },
        },
        Text: {
            variants: {
                secondary: {
                    color: 'grey30',
                },
            },
        },
        Toast: {
            variants: {
                error: {
                    bg: 'red',
                    color: 'white',
                },
                success: {
                    bg: 'green',
                    color: 'white',
                },
            },
        },
    },
    styles: {
        global: {
            body: {
                color: 'grey',
            },
        },
    },
});

export default theme;
