import {
    Heading,
    Button,
    Box,
    Text,
    VStack,
    Collapse,
    useDisclosure,
    Link,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import {
    ArrowCircleDown2,
    ArrowCircleUp2,
    ArrowUp,
} from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import DividerCurve from '../../common/components/DividerCurve';
import WatermarkBox from '../../common/components/WatermarkBox';
import MenuBar from '../../common/components/MenuBar';
import PageWidthContainer from '../../common/components/PageWidthContainer';
import i18n from '../../i18n';

const { REACT_APP_WVW_APP_URL } = process.env;

const Welcome = () => {
    const { isOpen, onToggle } = useDisclosure();

    const { t } = useTranslation('common');

    return (
        <Box
            w="100%"
            bgGradient="linear(to-b, #29777b, #016064)"
        >
            <WatermarkBox>
                <MenuBar />

                <PageWidthContainer>
                    <Box
                        textAlign="center"
                        w="100%"
                        paddingBlock="8rem"
                    >
                        <VStack spacing="2rem">
                            <Heading
                                color="white"
                                fontSize="2.7rem"
                            >
                                {t('welcomeHeader')}
                                <br />
                                {t('welcomeSubHeader')}
                            </Heading>

                            <Heading
                                size="md"
                                color="yellow"
                            >
                                {t('welcomeText')}
                            </Heading>

                            <Grid
                                maxW="container.sm"
                                gap="1em"
                                templateColumns="repeat(auto-fit, minmax(230px, 1fr))"
                                w="80%"
                                textAlign="center"
                            >
                                <GridItem>
                                    <Link
                                        href={`${REACT_APP_WVW_APP_URL}/language-redirect/${i18n.language}/register%2Fregister_valuer`}
                                    >
                                        <Button
                                            fontSize="1rem"
                                            borderRadius="2rem"
                                            bg="yellow"
                                            color="white"
                                            _hover={{
                                                bg: 'yellow',
                                                color: 'white',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {t('offeringValuations')}

                                            <ArrowUp transform="rotate(45)" size="1.2rem" />
                                        </Button>
                                    </Link>
                                </GridItem>
                                <GridItem>
                                    <Link
                                        href={`${REACT_APP_WVW_APP_URL}/language-redirect/${i18n.language}/register%2Fregister_client`}
                                    >
                                        <Button
                                            fontSize="1rem"
                                            borderRadius="2rem"
                                            bg="yellow"
                                            color="white"
                                            _hover={{
                                                bg: 'yellow',
                                                color: 'white',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {t('lookingForValuation')}

                                            <ArrowUp transform="rotate(45)" size="1.2rem" />
                                        </Button>
                                    </Link>
                                </GridItem>
                            </Grid>
                        </VStack>
                    </Box>
                </PageWidthContainer>

                <Box
                    w="100%"
                >
                    <DividerCurve />

                    <VStack
                        bg="white"
                        w="100%"
                        spacing="1rem"
                        paddingBottom="1rem"
                    >
                        <PageWidthContainer>
                            <Heading
                                color="yellow"
                                marginTop="1rem"
                                textAlign="center"
                                fontSize="2rem"
                                paddingTop="0.5rem"
                                paddingLeft="1rem"
                                paddingRight="1rem"
                            >
                                {t('areYouTired')}
                            </Heading>
                        </PageWidthContainer>

                        <Text
                            w={{
                                base: '100%', sm: '100%', md: '80%', lg: '80%', xl: '70%',
                            }}
                            textAlign={{
                                sm: 'justify',
                                md: 'center',
                            }}
                            fontSize="1rem"
                            paddingLeft="1rem"
                            paddingRight="1rem"
                        >
                            {t('allInOne')}
                        </Text>

                        <Collapse in={isOpen}>
                            <VStack spacing="4" marginLeft="2rem" marginRight="2rem">
                                <Text
                                    w={{
                                        base: '100%', sm: '100%', md: '80%', lg: '80%', xl: '70%',
                                    }}
                                    textAlign={{
                                        sm: 'justify',
                                        md: 'center',
                                    }}
                                    fontSize="1rem"
                                    paddingLeft="1rem"
                                    paddingRight="1rem"
                                >
                                    {t('readMoreContentPara1')}
                                </Text>

                                <Text
                                    w={{
                                        base: '100%', sm: '100%', md: '80%', lg: '80%', xl: '70%',
                                    }}
                                    textAlign={{
                                        sm: 'justify',
                                        md: 'center',
                                    }}
                                    fontSize="1rem"
                                >
                                    {t('readMoreContentPara2')}
                                </Text>
                                <Heading
                                    as="h4"
                                    size="md"
                                    textAlign="center"
                                    fontSize="1.2rem"
                                    paddingLeft="1rem"
                                    paddingRight="1rem"
                                >
                                    {t('experienceYourself')}
                                </Heading>
                            </VStack>
                        </Collapse>

                        <Button
                            variant="none"
                            onClick={onToggle}
                        >
                            <VStack>
                                <Text
                                    color="yellow"
                                >
                                    {!isOpen ? t('readMore') : t('readLess')}
                                </Text>
                                {!isOpen ? (
                                    <ArrowCircleDown2 size="2rem" color="rgba(250, 202, 22)" />
                                ) : (
                                    <ArrowCircleUp2 size="2rem" color="rgba(250, 202, 22)" />
                                )}
                            </VStack>
                        </Button>
                    </VStack>
                </Box>
            </WatermarkBox>
        </Box>
    );
};

export default Welcome;
