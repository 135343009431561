import {
    Text,
    Checkbox,
    SimpleGrid,
    Box,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { ReactNode } from 'react';

type PropTypes = {
    label: ReactNode | string,
    name: string,
    onChange?: (value: string, field?: FieldInputProps<string>) => void,
};

const FormikCheckbox = (props: PropTypes) => {
    const {
        label,
        name,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <SimpleGrid
            minChildWidth="max-content"
        >
            <Box width="max-content">
                <Checkbox
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    paddingBlock=".3rem"
                    onChange={e => {
                        field.onChange({ target: { name, value: e.target.checked } });

                        onChange?.(e.target.value, field);
                    }}
                >
                    {label}
                </Checkbox>
            </Box>

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                        textAlign="left"
                    >
                        {meta.error}
                    </Text>
                )}
        </SimpleGrid>
    );
};

FormikCheckbox.defaultProps = {
    onChange: () => { },
};

export default FormikCheckbox;
