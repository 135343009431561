import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Link,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ArrowUp } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import PageWidthContainer from './PageWidthContainer';
import WatermarkBox from './WatermarkBox';
import i18n from '../../i18n';

const { REACT_APP_WVW_APP_URL } = process.env;

const FooterHero = () => {
    const { t } = useTranslation('common');

    return (
        <PageWidthContainer>
            <Box
                bgGradient="linear(to-b, #29777b, #016064)"
                borderRadius="0.5rem"
                mt="-4rem"
                w="100%"
            >
                <WatermarkBox>
                    <Box
                        paddingBlock="2.2rem"
                        paddingInline="4rem"
                    >
                        <Center h="100%">
                            <VStack
                                align="left"
                                spacing={1}
                                w="100%"
                            >
                                <Text
                                    color="yellow"
                                    fontWeight="bold"
                                >
                                    {t('switchToday')}
                                </Text>

                                <Flex w="100%">
                                    <Heading
                                        color="white"
                                        size="lg"
                                    >
                                        {t('speedProduction')}
                                    </Heading>

                                    <Spacer />

                                    <Link
                                        href={`${REACT_APP_WVW_APP_URL}/language-redirect/${i18n.language}/register`}
                                    >
                                        <Button
                                            fontSize="1rem"
                                            borderRadius="2rem"
                                            bg="yellow"
                                            color="white"
                                            _hover={{
                                                bg: 'yellow',
                                                color: 'white',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {t('getStarted')}

                                            <ArrowUp transform="rotate(45)" size="1.2rem" />
                                        </Button>
                                    </Link>
                                </Flex>
                            </VStack>
                        </Center>
                    </Box>
                </WatermarkBox>
            </Box>
        </PageWidthContainer>
    );
};

export default FooterHero;
