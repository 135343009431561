import {
    Box,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

const WatermarkBox = ({ children } : { children: ReactNode }) => (
    <Box
        h="100%"
        w="100%"
        backgroundImage="url('/images/WVWPatternWithColor.svg')"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
    >
        {children}
    </Box>
);

export default WatermarkBox;
