import { Form, Formik, FormikValues } from 'formik';
import { ReactNode } from 'react';
import * as Yup from 'yup';

type PropTypes = {
    children: ReactNode[] | ReactNode;
    disabled?: boolean;
    initialValues: FormikValues;
    validationSchema?: Yup.ObjectSchema<{ [index: string]: string | number | boolean }>;
    onSubmit: (values: FormikValues) => void;
};

const FormikForm = (props: PropTypes) => {
    const {
        children,
        disabled,
        initialValues,
        validationSchema,
        onSubmit,
    } = props;

    return (
        <Formik
            disabled={disabled}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <Form
                style={{ width: '100%' }}
            >
                {children}
            </Form>
        </Formik>
    );
};

FormikForm.defaultProps = {
    disabled: false,
    validationSchema: undefined,
};

export default FormikForm;
