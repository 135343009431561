import Welcome from './HomePageSections/Welcome';
import ServiceOffer from './HomePageSections/ServiceOffer';
import Footer from '../common/components/Footer';
import FooterHero from '../common/components/FooterHero';

const Home = () => (
    <>
        <Welcome />

        {/* <Testimony /> */}

        <ServiceOffer />

        <FooterHero />

        <Footer />
    </>
);

export default Home;
