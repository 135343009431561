type PropTypes = {
    color?: string;
};

const DividerCurve = (props: PropTypes) => {
    const { color } = props;

    return (
        <div style={{ overflowX: 'hidden' }}>
            <div className="curve">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                        transform: 'scaleY(-1) translate(-2%, 0)',
                        width: '105vw',
                        height: '50px',
                    }}
                >
                    <path
                        d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                        className="shape-fill"
                        style={{ fill: color }}
                    />
                </svg>
            </div>
        </div>
    );
};

DividerCurve.defaultProps = {
    color: '#fff',
};

export default DividerCurve;
