import {
    Box,
    Button,
    HStack,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ArrowUp } from 'iconsax-react';
import { Link as ReactLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageWidthContainer from './PageWidthContainer';
import Logo from './Logo';

const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Footer = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <PageWidthContainer>
                <Box
                    paddingBlock="4rem"
                >
                    <HStack>
                        <Logo withColor />

                        <VStack
                            spacing="5px"
                            marginLeft="1rem"
                            align="left"
                        >
                            <Heading
                                color="green"
                                fontSize={{
                                    base: '0.8rem', sm: '0.8rem', md: '1rem', lg: '1rem', xl: '1rem',
                                }}
                                as="b"
                            >
                                {t('theFutureOf')}
                            </Heading>
                        </VStack>

                    </HStack>
                </Box>

                <Box
                    paddingBlock="0.5rem"
                    textAlign="center"
                    fontSize="0.8rem"
                    paddingBottom="2rem"
                >
                    <Text>
                        Copyright ©2023 Who Values What.  All rights reserved.

                        {'  '}

                        <Link
                            as={ReactLink}
                            to="/privacy"
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            {t('privacy')}
                        </Link>

                        {'  |  '}

                        <Link
                            as={ReactLink}
                            to="/terms"
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            {t('terms')}
                        </Link>

                        {'  |  '}

                        <Link
                            as={ReactLink}
                            to="/imprint"
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            {t('imprint')}
                        </Link>
                    </Text>
                </Box>
            </PageWidthContainer>

            <Box
                position="absolute"
                bottom="5rem"
                right="5rem"
            >
                <Button
                    h="50px"
                    w="50px"
                    bgColor="white"
                    borderRadius="60%"
                    border="1px"
                    onClick={handleScrollToTop}
                >
                    <ArrowUp size="20px" />
                </Button>
            </Box>
        </>
    );
};

export default Footer;
