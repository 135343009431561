import {
    Button,
    Circle,
    Flex,
    Heading,
    Img,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ArrowUp } from 'iconsax-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import {
    FormikCheckbox,
    FormikForm,
    FormikInput,
    FormikTextarea,
} from '../common/formik';
import useSendContactEmail from '../hooks/useSendContactEmail';
import LoadingSpinner from '../common/components/LoadingSpinner';

const { REACT_APP_GOOGLE_RECAPTCHA_KEY = '' } = process.env;

const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    town: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
    contactMe: Yup.boolean().isTrue('Please accept').required('Required'),
});

const ContactForm = () => {
    const { t } = useTranslation('common');

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [sent, setSent] = useState(false);

    const { send } = useSendContactEmail({
        onSuccess: () => {
            setSent(true);
            setSubmitting(false);
        },
        onError: () => {
            // console.log('error');
            setSubmitting(false);
        },
    });

    if (sent) {
        return (
            <VStack spacing="6" paddingTop="1rem">
                <Circle
                    size="sm"
                    alignSelf="top"
                    border="3rem solid"
                    borderColor="yellow"
                    backgroundColor="yellow80"
                >
                    <Circle
                        bg="white"
                        size="12rem"
                    >
                        <Img
                            src="/images/highfive.svg"
                            alt="highfive"
                            w="10rem"
                            paddingTop="3rem"
                        />
                    </Circle>
                </Circle>

                <Heading
                    as="h4"
                    size="lg"
                >
                    Sent!
                </Heading>

                <Heading
                    as="h4"
                    size="sm"
                >
                    We will get back to you as soon as possible.
                </Heading>
            </VStack>
        );
    }

    if (submitting) {
        return (
            <Flex alignItems="center">
                <LoadingSpinner
                    color="yellow"
                    noText
                    size="xl"
                    thickness="4px"
                />
            </Flex>
        );
    }

    return (
        <>
            <FormikForm
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    town: '',
                    country: '',
                    message: '',
                    contactMe: false,
                }}
                onSubmit={values => {
                    setSubmitting(true);

                    send({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phone: values.phone,
                        town: values.town,
                        country: values.country,
                        message: values.message,
                        recaptchaToken: recaptchaToken || '',
                    });
                }}
                validationSchema={validationSchema}
            >
                <VStack
                    spacing="4"
                    w="100%"
                >
                    <Flex
                        gap="6"
                        w="100%"
                    >
                        <FormikInput
                            name="firstName"
                            placeholder={t('firstName')}
                        />

                        <FormikInput
                            name="lastName"
                            placeholder={t('lastName')}
                        />
                    </Flex>

                    <FormikInput
                        name="email"
                        placeholder={t('email')}
                    />

                    <FormikInput
                        name="phone"
                        placeholder={t('phone')}
                    />

                    <Text
                        textAlign="left"
                        w="100%"
                    >
                        {t('address')}
                    </Text>

                    <FormikInput
                        name="town"
                        placeholder={t('town')}
                    />

                    <FormikInput
                        name="country"
                        placeholder={t('country')}
                    />

                    <FormikTextarea
                        name="message"
                        placeholder={t('howCanWeHelp')}
                    />

                    <FormikCheckbox
                        label={t('pleaseContactMeVia')}
                        name="contactMe"
                    />

                    <Button
                        disabled={submitting || recaptchaToken === null}
                        type="submit"
                        variant="primaryYellow"
                    >
                        {t('submit')}

                        <ArrowUp
                            transform="rotate(45)"
                            size="1.2rem"
                        />
                    </Button>
                </VStack>
            </FormikForm>

            <ReCAPTCHA
                style={{ display: 'inline-block' }}
                theme="light"
                size="invisible"
                ref={recaptchaRef}
                sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                onChange={(token: string | null) => setRecaptchaToken(token)}
                asyncScriptOnLoad={() => recaptchaRef.current?.execute()}
            />
        </>
    );
};

export default ContactForm;
