import { Grid, GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

const PageWidthContainer = ({ children }: { children: ReactNode | ReactNode[] }) => (
    <Grid
        templateRows="auto"
        templateColumns="repeat(6, 1fr)"
    >
        <GridItem
            rowStart={2}
            colStart={{
                base: 1, sm: 1, md: 2, lg: 2, xl: 2,
            }}
            colSpan={{
                base: 6, sm: 6, md: 4, lg: 4, xl: 4,
            }}
            paddingInline="1rem"
        >
            {children}
        </GridItem>
    </Grid>
);

export default PageWidthContainer;
