import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import './App.css';
import Router from './Router';
import theme from './theme';
import './index.css';

const queryClient = new QueryClient();

const App = () => {
    const { t } = useTranslation('common');

    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider
                theme={theme}
            >
                <>
                    <Router />

                    <CookieConsent
                        location="bottom"
                        buttonClasses="cookie-button"
                        buttonText={t('accept')}
                        cookieName="myAwesomeCookieName2"
                        style={{ background: '#2B373B' }}
                        buttonStyle={{
                            borderRadius: '2rem',
                            color: 'white',
                        }}
                        expires={150}
                    >
                        {t('cookieBlurb')}
                    </CookieConsent>
                </>
            </ChakraProvider>
        </QueryClientProvider>
    );
};

export default App;
