import {
    Box,
    Center,
    Container,
    HStack,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Call } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { EmailIcon } from '@chakra-ui/icons';
import Footer from '../common/components/Footer';
import MenuBar from '../common/components/MenuBar';
import ContactForm from '../components/ContactForm';

const ContactUs = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <MenuBar invertColor />

            <Container paddingBlock="3rem">
                <VStack
                    spacing="4"
                    textAlign="center"
                >
                    <Text
                        color="green"
                        fontWeight="bold"
                    >
                        {t('contactUs')}
                    </Text>

                    <Heading
                        size="lg"
                        color="green"
                    >
                        {t('chatToTeamHeader')}
                        <br />
                        {t('chatToTeamSubHeader')}
                    </Heading>

                    <Text>
                        {t('emailUsAt')}
                        <Link
                            color="yellow"
                            href="mailto:platform@wer-bewertet-was.de"
                        >
                            platform@wer-bewertet-was.de
                        </Link>
                        {t('orFillTheForm')}
                    </Text>

                    <ContactForm />
                </VStack>
            </Container>

            <Box
                bg="grey05"
                pt="4rem"
            >
                <HStack
                    align="stretch"
                    justify="center"
                    spacing="4"
                    paddingLeft="2rem"
                    paddingRight="2rem"
                >
                    <Box
                        h="15rem"
                        padding="1rem"
                        mb="1rem"
                        w="14rem"
                    >
                        <Heading
                            fontWeight="normal"
                            size="lg"
                            color="green"
                        >
                            {t('contactOur')}
                        </Heading>

                        <Heading
                            size="lg"
                            color="green"
                        >
                            {t('salesTeam')}
                        </Heading>
                    </Box>

                    <Box
                        borderRadius="12px"
                        boxShadow="0px 3px 6px #0000000D;"
                        bg="white"
                        h="15rem"
                        padding="1rem"
                        mb="1rem"
                        w="14rem"
                    >
                        <VStack
                            align="stretch"
                            spacing="3"
                        >
                            <Box
                                borderRadius="6px"
                                bg="green"
                                h="42px"
                                w="42px"
                            >
                                <Center h="100%">
                                    <EmailIcon
                                        color="white"
                                        fontSize="1.5rem"
                                    />
                                </Center>
                            </Box>

                            <Heading
                                color="green"
                                size="md"
                            >
                                {t('emailUs')}
                            </Heading>

                            <Text>
                                {t('writeTheTeam')}
                            </Text>

                            <Link
                                color="yellow"
                                fontWeight="bold"
                                href="mailto:platform@wer-bewertet-was.de"
                            >
                                {t('email')}
                            </Link>
                        </VStack>
                    </Box>

                    <Box
                        borderRadius="12px"
                        boxShadow="0px 3px 6px #0000000D;"
                        bg="white"
                        h="15rem"
                        padding="1rem"
                        mb="1rem"
                        w="14rem"
                    >
                        <VStack
                            align="stretch"
                            spacing="3"
                        >
                            <Box
                                borderRadius="6px"
                                bg="green"
                                h="42px"
                                w="42px"
                            >
                                <Center h="100%">
                                    <Call color="white" />
                                </Center>
                            </Box>

                            <Heading
                                color="green"
                                size="md"
                            >
                                {t('callUs')}
                            </Heading>

                            <Text>
                                {t('speakToOurTeam')}
                            </Text>

                            <Text
                                color="yellow"
                                fontWeight="bold"
                            >
                                +49 176 8204 7126
                            </Text>
                        </VStack>
                    </Box>
                </HStack>

                <Footer />
            </Box>
        </>
    );
};

export default ContactUs;
