import WVWLogoColor from './WVWLogoColor';
import WVWLogoWhite from './WVWLogoWhite';

const Logo = (props: { size?: string, withColor?: boolean }) => {
    const { size, withColor } = props;

    if (withColor) {
        return (
            <WVWLogoColor
                style={{
                    fontSize: size,
                }}
            />
        );
    }

    return (
        <WVWLogoWhite
            style={{ fontSize: size }}
        />
    );
};

Logo.defaultProps = {
    size: '6rem',
    withColor: false,
};

export default Logo;
