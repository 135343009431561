import {
    Box,
    Button,
    Collapse,
    Divider,
    Flex,
    HStack,
    Icon,
    IconButton,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { DE, GB } from 'country-flag-icons/react/3x2';
import { ArrowUp } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Logo from './Logo';
import PageWidthContainer from './PageWidthContainer';
import i18n from '../../i18n';

const { REACT_APP_WVW_APP_URL } = process.env;

type PropTypes = {
    invertColor?: boolean;
};

const MenuBar = (props: PropTypes) => {
    const { invertColor } = props;

    const { pathname = '/' } = useLocation();
    const { t } = useTranslation('common');
    const { isOpen, onToggle } = useDisclosure();

    const [languageOpen, setLanguageOpen] = useState(false);

    const linkColor = invertColor ? 'green' : 'white';

    return (
        <PageWidthContainer>
            <Box
                h="8rem"
                w="100%"
            >
                <Flex
                    align="center"
                    gap="12"
                    h="100%"
                    w="100%"
                >
                    <Logo withColor={invertColor} />

                    <Spacer display={{ base: 'normal', lg: 'none' }} />

                    <HStack
                        display={{ base: 'normal', lg: 'none' }}
                        spacing="4"
                    >
                        <IconButton
                            aria-label="Language"
                            bg="tranparent"
                            icon={i18n.language === 'de' ? <Icon as={DE} fontSize="1.5rem" /> : <Icon as={GB} fontSize="1.5rem" />}
                            size="sm"
                            onClick={() => setLanguageOpen(true)}
                        />

                        <IconButton
                            onClick={onToggle}
                            icon={isOpen
                                ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                            variant="ghost"
                            color={linkColor}
                            aria-label="Toggle Navigation"
                        />
                    </HStack>

                    <Flex
                        display={{ base: 'none', lg: 'flex' }}
                        gap="6"
                    >
                        { pathname === '/' && (
                            <Link
                                href="#service-offer"
                            >
                                <Button
                                    color={linkColor}
                                    variant="link"
                                >
                                    {t('solutions')}
                                </Button>
                            </Link>
                        )}

                        { pathname !== '/' && (
                            <Link
                                as={ReactLink}
                                to="/"
                            >
                                <Button
                                    color={linkColor}
                                    variant="link"
                                >
                                    {t('solutions')}
                                </Button>
                            </Link>
                        )}

                        <Link
                            as={ReactLink}
                            to="/contact"
                        >
                            <Button
                                color={linkColor}
                                variant="link"
                            >
                                {t('contactUs')}
                            </Button>
                        </Link>
                    </Flex>

                    <Spacer display={{ base: 'none', lg: 'block' }} />

                    <Flex
                        display={{ base: 'none', lg: 'flex' }}
                        gap="6"
                    >
                        <IconButton
                            aria-label="Language"
                            bg="tranparent"
                            icon={i18n.language === 'de' ? <Icon as={DE} fontSize="1.5rem" /> : <Icon as={GB} fontSize="1.5rem" />}
                            size="sm"
                            onClick={() => setLanguageOpen(true)}
                        />

                        <Link
                            href={`${REACT_APP_WVW_APP_URL}/language-redirect/${i18n.language}/`}
                        >
                            <Button
                                variant="primaryYellow"
                                _hover={{
                                    bg: 'yellow',
                                    color: 'white',
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('login')}

                                <ArrowUp
                                    transform="rotate(45)"
                                    size="1.2rem"
                                />
                            </Button>
                        </Link>
                    </Flex>
                </Flex>

                { invertColor && <Divider /> }
            </Box>

            <Collapse
                in={isOpen}
                animateOpacity
            >
                <VStack
                    align="start"
                    spacing="4"
                    paddingTop="2rem"
                    paddingInline="1rem"
                >
                    { pathname === '/' && (
                        <Link
                            href="#service-offer"
                        >
                            <Button
                                color={invertColor ? 'green' : 'white'}
                                variant="link"
                            >
                                {t('solutions')}
                            </Button>
                        </Link>
                    )}

                    { pathname !== '/' && (
                        <Link
                            as={ReactLink}
                            to="/"
                        >
                            <Button
                                color={invertColor ? 'green' : 'white'}
                                variant="link"
                            >
                                {t('solutions')}
                            </Button>
                        </Link>
                    )}

                    <Link
                        as={ReactLink}
                        to="/contact"
                    >
                        <Button
                            color={invertColor ? 'green' : 'white'}
                            variant="link"
                        >
                            {t('contactUs')}
                        </Button>
                    </Link>
                </VStack>
            </Collapse>

            <Modal
                isOpen={languageOpen}
                onClose={() => setLanguageOpen(false)}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t('selectLanguage')}
                    </ModalHeader>

                    <ModalBody>
                        <VStack spacing="4">
                            <Button
                                onClick={() => {
                                    i18n.changeLanguage('en');
                                    setLanguageOpen(false);
                                }}
                                w="100%"
                                leftIcon={<Icon as={GB} fontSize="2rem" />}
                            >
                                English
                            </Button>

                            <Button
                                onClick={() => {
                                    i18n.changeLanguage('de');
                                    setLanguageOpen(false);
                                }}
                                w="100%"
                                leftIcon={<Icon as={DE} fontSize="2rem" />}
                            >
                                Deutsch
                            </Button>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={() => setLanguageOpen(false)}
                        >
                            {t('close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </PageWidthContainer>
    );
};

MenuBar.defaultProps = {
    invertColor: false,
};

export default MenuBar;
