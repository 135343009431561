import {
    Grid,
    GridItem,
    Heading,
    VStack,
    Center,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ServiceOffer = () => {
    const { t } = useTranslation('common');

    return (
        <VStack
            bgColor="grey05"
            id="service-offer"
            paddingBlock="2rem"
            pb="10rem"
            spacing="5"
            w="100%"
        >
            <Heading
                color="yellow"
                fontSize="2rem"
                paddingBottom="1rem"
                paddingLeft="1rem"
                paddingRight="1rem"
            >
                {t('serviceOfferHead')}
            </Heading>

            <Grid
                templateRows="auto"
                templateColumns="repeat(6, 1fr)"
                gap={6}
                paddingLeft={{
                    base: '1rem', sm: '1rem', md: '0', lg: '0', xl: '0',
                }}
                paddingRight={{
                    base: '1rem', sm: '1rem', md: '0', lg: '0', xl: '0',
                }}
            >
                <GridItem
                    rowStart={{
                        base: 1, sm: 1, md: 1, lg: 1, xl: 1,
                    }}
                    rowSpan={{
                        base: 1, sm: 1, md: 1, lg: 1, xl: 1,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 2, xl: 2,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <VStack
                            align="left"
                        >
                            <Heading
                                color="yellow"
                                fontSize="1rem"
                            >
                                {t('offerContactSubHead')}
                            </Heading>

                            <Heading
                                as="b"
                                color="green"
                                fontSize="1.5rem"
                            >
                                {t('offerContractHead')}
                            </Heading>

                            <UnorderedList paddingLeft="4">
                                <ListItem>
                                    {t('offerContractBody1')}
                                </ListItem>

                                <ListItem>
                                    {t('offerContractBody2')}
                                </ListItem>

                                <ListItem>
                                    {t('offerContractBody3')}
                                </ListItem>
                            </UnorderedList>
                        </VStack>
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 2, sm: 2, md: 2, lg: 1, xl: 1,
                    }}
                    rowSpan={{
                        base: 1, sm: 1, md: 1, lg: 1, xl: 1,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 4, xl: 4,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <img
                            src="/images/ServiceOfferContractTerms.png"
                            alt="Offer and contract functionality"
                            style={{
                                aspectRatio: 3 / 2,
                                width: '24rem',
                            }}
                        />
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 4, sm: 4, md: 4, lg: 2, xl: 2,
                    }}
                    colStart={{
                        base: 2, sm: 1, md: 2, lg: 2, xl: 2,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <img
                            src="/images/ServiceOfferDocumentProcurement.png"
                            alt="Document procurement functionality"
                            style={{
                                aspectRatio: 3 / 2,
                                width: '24rem',
                            }}
                        />
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 3, sm: 3, md: 3, lg: 2, xl: 2,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 4, xl: 4,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <VStack
                            align="left"
                        >
                            <Heading
                                color="yellow"
                                fontSize="1rem"
                            >
                                {t('docProcurementSubHead')}
                            </Heading>
                            <Heading
                                as="b"
                                color="green"
                                fontSize="1.5rem"
                            >
                                {t('docProcurementHead')}
                            </Heading>

                            <UnorderedList paddingLeft="4">
                                <ListItem>
                                    {t('docProcurementBody1')}
                                </ListItem>

                                <ListItem>
                                    {t('docProcurementBody2')}
                                </ListItem>

                                <ListItem>
                                    {t('docProcurementBody3')}
                                </ListItem>
                            </UnorderedList>
                        </VStack>
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 5, sm: 5, md: 5, lg: 3, xl: 3,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 2, xl: 2,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <VStack
                            align="left"
                        >
                            <Heading
                                color="yellow"
                                fontSize="1rem"
                            >
                                {t('secureEasyPaymentSubHead')}
                            </Heading>
                            <Heading
                                as="b"
                                color="green"
                                fontSize="1.5rem"
                            >
                                {t('secureEasyPaymentHead')}
                            </Heading>

                            <UnorderedList paddingLeft="4">
                                <ListItem>
                                    {t('secureEasyPaymentBody1')}
                                </ListItem>

                                <ListItem>
                                    {t('secureEasyPaymentBody2')}
                                </ListItem>

                                <ListItem>
                                    {t('secureEasyPaymentBody3')}
                                </ListItem>
                            </UnorderedList>
                        </VStack>
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 6, sm: 6, md: 6, lg: 3, xl: 3,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 4, xl: 4,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <img
                            src="/images/ServiceOfferSecureEasyPayment.png"
                            alt="Secure and easy payment functionality"
                            style={{
                                aspectRatio: 3 / 2,
                                width: '24rem',
                            }}
                        />
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 8, sm: 8, md: 8, lg: 4, xl: 4,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 2, xl: 2,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <img
                            src="/images/ServiceOfferInspectionScheduling.png"
                            alt="Inspection scheduling functionality"
                            style={{
                                aspectRatio: 3 / 2,
                                width: '24rem',
                            }}
                        />
                    </Center>
                </GridItem>

                <GridItem
                    rowStart={{
                        base: 7, sm: 7, md: 7, lg: 4, xl: 4,
                    }}
                    colStart={{
                        base: 1, sm: 1, md: 2, lg: 4, xl: 4,
                    }}
                    colSpan={{
                        base: 6, sm: 6, md: 4, lg: 2, xl: 2,
                    }}
                >
                    <Center h="100%">
                        <VStack
                            align="left"
                        >
                            <Heading
                                color="yellow"
                                fontSize="1rem"
                            >
                                {t('inspectionSchedulingSubHead')}
                            </Heading>
                            <Heading
                                as="b"
                                color="green"
                                fontSize="1.5rem"
                            >
                                {t('inspectionSchedulingHead')}
                            </Heading>

                            <UnorderedList paddingLeft="4">
                                <ListItem>
                                    {t('inspectionSchedulingBody1')}
                                </ListItem>

                                <ListItem>
                                    {t('inspectionSchedulingBody2')}
                                </ListItem>

                                <ListItem>
                                    {t('inspectionSchedulingBody3')}
                                </ListItem>
                            </UnorderedList>
                        </VStack>
                    </Center>
                </GridItem>
            </Grid>
        </VStack>
    );
};

export default ServiceOffer;
